import React, { useCallback, useEffect, useState } from 'react';
import Filter from 'ui/component/filter/filter';
import { Tag } from 'ui/util/type-helper';
import BlogListing from './blog-listing';

import styles from './style.css';

type PostCategory = {
    value: string;
    label: string;
};

interface BlogProps {
    storeId: number;
    filters?: PostCategory[];
    tags?: Tag[];
    defaultTagId?: number | null;
}

const MemoizedFilter = React.memo(Filter);

const BlogListingCover: React.FC<BlogProps> = (
    {
        storeId,
        filters,
        tags,
        defaultTagId,
    },
) => {
    const [activeValue, setActiveValue] = useState<string>('most_recent');
    const [tagId, setTagId] = useState<number | null>(typeof defaultTagId === 'undefined' ? null : defaultTagId);

    useEffect(() => {
        if (defaultTagId !== undefined) {
            setTagId(defaultTagId);
        }
    }, [defaultTagId]);

    const handleFilterOnClick = useCallback((code: string | null) => {
        setActiveValue(code || '');
    }, []);

    const handleTagOnClick = useCallback((tagId: number | null) => {
        setTagId(tagId);
    }, []);

    return (
        <div className={styles.listingSection}>
            {filters && (
                <MemoizedFilter
                    tags={tags}
                    filter={filters}
                    onClick={handleFilterOnClick}
                    onTagClick={handleTagOnClick}
                    activeValue={activeValue}
                    tagId={tagId}
                />
            )}
            <BlogListing
                onTagClick={handleTagOnClick}
                storeId={storeId}
                code={activeValue}
                tagId={tagId}
            />
        </div>
    );
};

export default BlogListingCover;
