import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';

import styles from './style.css';

interface CardProps {
    imageUrl: string;
    imageAlt: string;
    btnText: string;
    redirectUrl: string;
    title: string;
    className?: string;
    cardClassName?: string;
    mobileImgUrl?: string;
}

const SpecialCard: React.FC<CardProps> = ({
    imageUrl,
    imageAlt,
    title,
    btnText,
    redirectUrl,
    className = '',
    cardClassName = '',
    mobileImgUrl,
}) => (
    <div className={classes(styles.specialCardCnt, className)}>
        <div className={classes(styles.specialCardWrapper, {
            [cardClassName]: cardClassName,
        })}
        >
            <div
                className={styles.specialCardImgCnt}
            >
                <img
                    src={imageUrl}
                    alt={imageAlt}
                    className={classes(styles.specialCardImgEle, {
                        [styles.desktopImageEle]: mobileImgUrl,
                    })}
                />
                {mobileImgUrl && (
                    <img
                        src={mobileImgUrl}
                        alt={imageAlt}
                        className={classes(styles.specialCardImgEle, styles.mobileImageEle)}
                    />
                )}
            </div>
            <div className={styles.specialCardContentCnt}>
                <div className={styles.mobileRelCss}>
                    <div className={styles.specialTitleCnt}>
                        <span>{title}</span>
                    </div>
                    {btnText && (
                        <AccessibleButton
                            tag="a"
                            action={() => {
                                window.location.assign(redirectUrl);
                            }}
                            className={styles.specialBtnCnt}
                        >
                            {btnText}
                        </AccessibleButton>
                    )}
                </div>
            </div>
        </div>
    </div>
);

export default SpecialCard;
