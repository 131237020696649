import React from 'react';
import { classes } from '@silkpwa/module/util/classes';

import styles from './style.css';

interface TagProps {
    name: string;
    id: number;
    onClick?: (id: number|null) => void;
    className?: string;
    setTagId?: React.Dispatch<React.SetStateAction<number | null>>;
}

const Tag: React.FC<TagProps> = ({
    name,
    id,
    onClick,
    className = '',
}) => (
    <>
        {
            onClick ? (
                <div
                    className={classes(styles.tagContainer, {
                        [className]: className,
                    })}
                    onClick={() => onClick(id)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={e => e.key === 'Enter' && onClick(id)}
                >
                    {name}
                </div>
            ) : (
                <div
                    className={classes(styles.tagContainer, {
                        [className]: className,
                    })}
                >
                    {name}
                </div>
            )
        }
    </>
);

export default Tag;
