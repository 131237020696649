import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Card from 'ui/component/card/card';
import { BlogPost, BlogPostData } from 'ui/util/type-helper';
import { LoadingImage } from 'ui/component/loading-image';
import { GET_BLOG_POST_LIST } from 'graphql/blog/blog-post-list';
import SpecialCard from 'ui/component/card/special-card';

import styles from './style.css';

interface BListProps {
    storeId: number;
    code: string;
    tagId: number | null;
    onTagClick?: (tagId: (number | null)) => void;
}

const BlogListing: React.FC<BListProps> = (
    {
        storeId,
        code,
        tagId,
        onTagClick,
    },
) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [posts, setPosts] = useState<BlogPost[]>([]);

    const {
        loading,
        error,
        data,
        refetch,
    } = useQuery<BlogPostData>(GET_BLOG_POST_LIST, {
        variables: {
            storeId,
            tag: tagId,
            category: code,
            pageSize: 9,
            currentPage,
        },
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        setCurrentPage(1);
        refetch({
            storeId,
            tag: tagId,
            category: code,
            pageSize: 9,
            currentPage: 1,
        });
        setPosts([]);
    }, [code, tagId]);

    useEffect(() => {
        if (data?.getPosts?.items) {
            if (currentPage > 1) {
                setPosts(prevPosts => [...prevPosts, ...data.getPosts.items]);
            } else {
                setPosts(_ => [...data.getPosts.items]);
            }
        }
        if (data?.getPosts.page_info?.total_pages) {
            setTotalPage(data.getPosts.page_info?.total_pages);
        }
    }, [data]);

    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 &&
            !loading &&
            currentPage < totalPage
        ) {
            refetch({
                currentPage: currentPage + 1,
            });
            setCurrentPage(prev => prev + 1);
        }
    }, [currentPage, loading, totalPage]);

    useEffect(() => {
        const onScroll = () => handleScroll();
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [handleScroll]);

    if (loading && currentPage === 1) {
        return (
            <div className={styles.smallLoadingImage}>
                <LoadingImage />
            </div>
        );
    }

    if (error) return null;

    return (
        <>
            <div className={styles.cardListCnt}>
                { posts && posts.map((post) => {
                    if (post?.is_add_post) {
                        return (
                            <SpecialCard
                                imageUrl={post.desktop_banner_url ? post.desktop_banner_url : ''}
                                imageAlt={post.title}
                                btnText={post.btn_text}
                                redirectUrl={post.redirect_url}
                                title={post.title}
                                className={styles.cardWidthStyle}
                                cardClassName={styles.boxStyleCnt}
                                mobileImgUrl={post.mobile_banner_url ? post.mobile_banner_url : undefined}
                            />
                        );
                    }
                    return (
                        <Card
                            key={post.entity_id}
                            imageUrl={post.desktop_banner_url ? post.desktop_banner_url : ''}
                            imageAlt={post.title}
                            author={post.author}
                            date={post.created_at}
                            title={post.title}
                            description={post.short_description}
                            url_key_path={post.url_key_path}
                            fb_share_link={post?.fb_share_url}
                            twitter_share_link={post?.twitter_share_url}
                            tags={post.tags}
                            className={styles.cardWidthStyle}
                            isListingPage
                            cardClassName={styles.boxStyleCnt}
                            mobileImageUrl={post.mobile_banner_url ? post.mobile_banner_url : undefined}
                            onTagClick={onTagClick}
                            displayIcon
                        />
                    );
                })}
            </div>
            { loading && (
                <div className={styles.smallLoadingImage}>
                    <LoadingImage />
                </div>
            )}
        </>
    );
};

export default BlogListing;
