import React from 'react';
import fb from 'ui/component/svg/facebook/fb.svg';
import twitter from 'ui/component/svg/twitter/x.svg';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';

import styles from './style.css';

interface SocialProps {
    fbShareLink: string;
    twitterShareLink: string;
    fbImg?: string;
    twitterImg?: string;
}

const SocialShare: React.FC<SocialProps> = ({
    fbShareLink, twitterShareLink, fbImg, twitterImg,
}) => (
    <>
        <div className={styles.shareCnt}>
            <span>SHARE</span>
            <AccessibleButton
                tag="a"
                target="_blank"
                href={fbShareLink}
                action={() => { /** Not needed this here */ }}
                className={styles.linkCnt}
            >
                <span
                    className={classes(styles.shareBtnCnt, {
                        [styles.shareSvgBtnCnt]: fbImg,
                        [styles.shareNormalBtnCnt]: !fbImg,
                    })}
                    data-link={fbShareLink}
                >
                    <img
                        src={fbImg || fb}
                        alt="facebook"
                        className={classes(styles.shareBtnImgCnt, {
                            [styles.svgImgCnt]: fbImg,
                            [styles.iconImgCnt]: !fbImg,
                        })}
                    />
                </span>
            </AccessibleButton>
            <AccessibleButton
                tag="a"
                target="_blank"
                href={twitterShareLink}
                action={() => { /** Not needed this here  */ }}
                className={classes(styles.linkCnt, {
                    [styles.svgCnt]: twitterImg,
                })}
            >
                <span
                    className={classes(styles.shareBtnCnt, {
                        [styles.shareSvgBtnCnt]: twitterImg,
                        [styles.shareNormalBtnCnt]: !twitterImg,
                    })}
                    data-link={twitterShareLink}
                >
                    <img
                        src={twitterImg || twitter}
                        alt="twitter"
                        className={classes(styles.shareBtnImgCnt, {
                            [styles.svgImgCnt]: twitterImg,
                            [styles.iconImgCnt]: !twitterImg,
                        })}
                    />
                </span>
            </AccessibleButton>
        </div>
    </>
);

export default SocialShare;
