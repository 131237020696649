import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_BLOG_LIST_LAYOUT: TypedDocumentNode = gql`
    query GET_BLOG_LIST_LAYOUT(
        $storeId: Int!
    ){
        getBlogListLayout(store_id: $storeId) {
            featured_post {
                store_id
                title
                created_at
                short_description
                desktop_banner_url
                mobile_banner_url
                fb_share_url
                twitter_share_url
                url_key_path
                author
                tags {
                    tag_id
                    name
                }
            }
            title
            description
            banner_image
            tags {
                tag_id
                name
            }
            categories {
                value
                label
            }
        }
    }
`;
