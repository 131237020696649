import React from 'react';
import Card from 'ui/component/card/card';
import { FeaturedPost } from 'ui/util/type-helper';

import styles from './style.css';

interface FeatureProps {
    featuredPost: FeaturedPost;
    onTagClick?: (tagId: (number | null)) => void;
}

const FeaturedBlog: React.FC<FeatureProps> = ({ featuredPost, onTagClick }) => {
    const {
        desktop_banner_url: desktopBannerUrl,
        tags,
        title,
        url_key_path: urlKeyPath,
        short_description: shortDescription,
        created_at: createdAt,
        author,
        fb_share_url: fbShareUrl,
        twitter_share_url: twitterShareUrl,
        mobile_banner_url: mobileBannerUrl,
    } = featuredPost;
    return (
        <div className={styles.featuredSection}>
            <Card
                imageUrl={desktopBannerUrl}
                imageAlt={title}
                author={author}
                url_key_path={urlKeyPath}
                date={createdAt}
                title={title}
                description={shortDescription}
                layout="flex-row"
                tags={tags}
                fb_share_link={fbShareUrl}
                twitter_share_link={twitterShareUrl}
                mobileImageUrl={mobileBannerUrl}
                isFeatured
                onTagClick={onTagClick}
            />
        </div>
    );
};

export default FeaturedBlog;
