import React from 'react';
import { classes } from '@silkpwa/module/util/classes';

import styles from './style.css';

interface SProps {
    fil: SingleLabelProps;
    onClick: (code: string) => void;
    activeValue?: string;
}

interface SingleLabelProps {
    label: string;
    value: string;
}

const SingleLabel: React.FC<SProps> = ({
    fil: { label, value },
    onClick,
    activeValue = 'most_recent',
}) => (
    <div
        className={classes(styles.singleLabelCnt, {
            [styles.activeLabelCnt]: activeValue === value,
        })}
        onClick={() => onClick(value)}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                onClick(value);
            }
        }}
    >
        <span className={styles.labelCnt} data-value={value}>{label}</span>
    </div>
);

export default SingleLabel;
