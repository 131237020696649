import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from 'graphql/base';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import BlogContent from './blog-content';

const BlogPage: React.FC = () => {
    const storeConfig = useConfig();
    if (!storeConfig?.extension_attributes?.cw_blog_enable) {
        window.location.href = window.location.origin;
    }
    return (
        <ApolloProvider client={GraphQlClient}>
            <BlogContent />
        </ApolloProvider>
    );
};

export default BlogPage;
