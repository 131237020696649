import React from 'react';

import style from './style.css';

interface HeroProps {
    imageUrl: string;
    altText: string;
    description?: string;
}

const HeroBanner: React.FC<HeroProps> = ({ imageUrl, altText }) => (
    <div className={style.bannerImageCnt}>
        <img
            src={imageUrl}
            alt={altText}
            className={style.bannerImageEle}
        />
    </div>
);

export default HeroBanner;
