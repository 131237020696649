import React from 'react';
import SelectBox from 'ui/component/select-box/select-box';
import { generateUniqueKey } from 'ui/util/validator-helper';
import SingleLabel from './single-label/single-label';

import style from './style.css';

interface FProps {
    filter: {
        value: string;
        label: string;
    }[];
    onClick: (code: string) => void;
    onTagClick: (tagId: number|null) => void;
    activeValue?: string;
    tags?: Tag[];
    tagId?: number|null;
}

type Tag = {
    // eslint-disable-next-line camelcase
    tag_id: number;
    name: string;
};

const Filter: React.FC<FProps> = ({
    filter,
    onClick,
    onTagClick,
    activeValue,
    tags,
    tagId,
}) => {
    const tagOptions = tags?.map(tag => ({
        value: tag.tag_id,
        label: tag.name,
    })) || [];

    return (
        <>
            {tagOptions.length > 0 && (
                <div className={style.displayOnMobile}>
                    <SelectBox
                        id="tag-select"
                        name="tags"
                        options={tagOptions}
                        onChange={onTagClick}
                        defaultValue={tagId || undefined}
                    />
                </div>
            )}
            <div className={style.filterMainCnt}>
                {filter && filter.map((fil, i) => (
                    <SingleLabel fil={fil} key={generateUniqueKey(i)} onClick={onClick} activeValue={activeValue} />
                ))}
                {tagOptions.length > 0 && (
                    <div className={style.displayOnDesktop}>
                        <SelectBox
                            id="tag-select"
                            name="tags"
                            options={tagOptions}
                            onChange={onTagClick}
                            defaultValue={tagId || undefined}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default Filter;
