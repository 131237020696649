import React from 'react';
import tagImg from 'assets/images/tag.png';

import CustomSelect from './custome-select';

interface SelectBoxProps {
    id?: string;
    name?: string;
    label?: string;
    options: { value: number; label: string }[];
    onChange: (tagId: number|null) => void;
    disabled?: boolean;
    className?: string;
    defaultValue?: number;
}

const SelectBox: React.FC<SelectBoxProps> = ({
    label,
    options,
    onChange,
    className = '',
    defaultValue,
}) => (
    <>
        <CustomSelect
            defaultValue={defaultValue}
            options={options}
            label={label}
            className={className}
            onChange={onChange}
            imgSrc={tagImg}
        />
    </>
);

export default SelectBox;
