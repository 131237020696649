import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_BLOG_POST_LIST: TypedDocumentNode = gql`
    query GET_BLOG_POST_LIST(
        $storeId: Int!
        $tag: Int
        $pageSize: Int
        $currentPage: Int
        $category: String
    ){
        getPosts(store_id: $storeId, tag: $tag, pageSize: $pageSize, currentPage: $currentPage, category: $category) {
            items {
                entity_id
                store_id
                title
                short_description
                created_at
                desktop_banner_url
                mobile_banner_url
                url_key_path
                author
                is_add_post
                redirect_url
                fb_share_url
                twitter_share_url
                btn_text
                tags {
                    tag_id
                    name
                }
            }
            total_count
            page_info {
                current_page
                page_size
                total_pages
            }
        }
    }
`;
