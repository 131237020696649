import React, { useState, useEffect, useRef } from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { generateUniqueKey } from 'ui/util/validator-helper';
import customStyle from './custom-style.css';

interface Option {
    value: number;
    label: string;
}

interface CustomSelectProps {
    options: Option[];
    imgSrc?: string;
    label?: string;
    className?: string;
    onChange: (tagId: number|null) => void;
    defaultValue?: number;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
    options,
    imgSrc,
    label,
    className,
    onChange,
    defaultValue,
}) => {
    const [selectedOption, setSelectedOption] = useState<number | null>(
        defaultValue ?? null,
    );
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(prev => !prev);
    const t = usePhraseTranslater();

    const handleSelect = (value: number) => {
        const newValue = selectedOption === value ? null : value;
        setSelectedOption(newValue);
        onChange(newValue);
        setIsOpen(false);
    };

    useEffect(() => {
        if (defaultValue !== undefined) {
            setSelectedOption(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`${customStyle.selectBox} ${className}`} ref={dropdownRef}>
            {label && <span>{label}</span>}
            <AccessibleButton
                tag="div"
                action={() => toggleDropdown()}
                className={customStyle.selectedBox}
            >
                <span className={classes(customStyle.placeholder, {
                    [customStyle.iconArrowBack]: !isOpen,
                    [customStyle.iconArrowUp]: isOpen,
                })}
                >
                    {options.find(opt => opt.value === selectedOption)?.label || t('Search by Tag')}
                </span>
            </AccessibleButton>

            {isOpen && (
                <div className={customStyle.dropdownMenu}>
                    {options.map((option, i) => (
                        <AccessibleButton
                            key={generateUniqueKey(i)}
                            tag="div"
                            action={() => handleSelect(option.value)}
                            className={classes(customStyle.dropdownItem, {
                                [customStyle.selected]: selectedOption === option.value,
                                [customStyle.tagRemove]: selectedOption === option.value,
                            })}
                        >
                            {imgSrc && <img src={imgSrc} alt={option.label} className={customStyle.optionImg} />}
                            {option.label}
                        </AccessibleButton>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
